import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/@splidejs/splide/dist/css/splide.min.css';
import '../../node_modules/lity/dist/lity.min.css';
import '../styles/index.scss';

import Lity from 'lity/dist/lity';
import Splide from '@splidejs/splide';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
  require('../profile.html');
  require('../article.html');
  require('../all-article.html');
  require('../information.html');
  require('../information-categories.html');
}
